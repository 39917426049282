import React, { useEffect } from "react"

import useAuthContext from "decentraland-gatsby/dist/context/Auth/useAuthContext"

import { Props } from "./Intercom.types"
import { IntercomWidget } from "./IntercomWidget"

function isErrorWithMessage(error: unknown): error is Error {
  return (
    error !== undefined &&
    error !== null &&
    typeof error === "object" &&
    "message" in error
  )
}

const Intercom: React.FC<Props> = (props) => {
  const widget = IntercomWidget.getInstance()
  const [account, { providerType }] = useAuthContext()

  useEffect(() => {
    console.log("Initializing Intercom widget")

    if (!widget.appId) {
      console.log("Setting up Intercom widget with appId:", props.appId)
      widget.init(props.appId, props.settings)
    } else {
      if (widget.appId !== props.appId) {
        throw new Error(
          `Intercom widget already initialized with app id "${props.appId}". Only one intercom widget is allowed.`
        )
      }
    }

    const widgetData = {
      ...props.data,
      ...(account ? { Wallet: account } : {}),
      ...(providerType ? { "Wallet type": providerType } : {}),
    }

    const renderWidget = async () => {
      try {
        console.log("Injecting Intercom widget script")
        await widget.inject()
        console.log("Rendering Intercom widget")
        widget.render(widgetData)
      } catch (error: any) {
        console.error(
          "Could not render intercom",
          isErrorWithMessage(error) ? error.message : "Unknown error"
        )
      }
    }

    renderWidget()

    return () => {
      console.log("Unmounting Intercom widget")
      widget.unmount()
    }
  }, [props.appId, props.settings, props.data, account, providerType])

  useEffect(() => {
    widget.settings = props.settings
    console.log("Updated Intercom widget settings:", props.settings)
  }, [props.settings])

  return null
}

Intercom.defaultProps = {
  data: {},
  settings: {
    alignment: "left",
    horizontalPadding: 10,
    verticalPadding: 10,
  },
}

export default Intercom
